<template>
	<div class="index-body">
		<div class="index">
			<audio
					ref="bgAudio"
					class="audio-bg"
					autoplay
					loop
					@play="onPlay"
					@pause="onPause"
					src="@/assets/bgm.wav">
				Your browser does not support the
				<code>audio</code> element.
			</audio>
			<audio src="@/assets/mp3_1.mp3" ref="clickAudio"></audio>
			<div class="music" @click="playAudio">
				<img src="@/assets/music.png" alt="" class="img_rotate" :class="{'img_paused': !paused}">
			</div>
			<img @mouseover="_playAudio" @click="jump('http://swap.amateras.io/')" class="index-img stake-img" :src="stake_img" alt="">
			<img @mouseover="_playAudio" @click="jump('/app/Game/BuyBox')" class="index-img nft-img" :src="nft_img" alt="">
			<img @mouseover="_playAudio" @click="jump('https://farms.amateras.io/farms')" class="index-img swap-img" :src="swap_img" alt="">
			<img @mouseover="_playAudio" @click="jump('https://www.amateras.io/')" class="index-img info-img" :src="info_img" alt="">
			<img @mouseover="_playAudio" @click="jump('/app/faq')" class="index-img about-img" :src="about_img" alt="">
			<img @mouseover="_playAudio" @click="jump('/app')" class="index-img dash-img" :src="dash_img" alt="">
		</div>
	</div>
</template>

<script>
export default {
	name: 'Index',
	data () {
		return {
			clientHeight: 0,
			clientWidth: 0,
			paused: false,
			bgAudio: null,
		}
	},
	computed: {
		is_mobile () {
			return this.$vuetify.breakpoint.mobile
		},
		stake_img () {
			return this.is_mobile ? require('@/assets/index_m/stake.png') : require('@/assets/index_pc/stake.png')
		},
		nft_img () {
			return this.is_mobile ? require('@/assets/index_m/nft.png') : require('@/assets/index_pc/NFT.png')
		},
		swap_img () {
			return this.is_mobile ? require('@/assets/index_m/swap.png') : require('@/assets/index_pc/swap.png')
		},
		info_img () {
			return this.is_mobile ? require('@/assets/index_m/info.png') : require('@/assets/index_pc/info.png')
		},
		about_img () {
			return this.is_mobile ? require('@/assets/index_m/about.png') : require('@/assets/index_pc/about.png')
		},
		dash_img () {
			return this.is_mobile ? require('@/assets/index_m/dash.png') : require('@/assets/index_pc/dash.png')
		},
	},
	created () {
		window.onresize = this.onresize
	},
	mounted () {
		this.$nextTick(() => {
			this.setSize()
			this.bgAudio = this.$refs.bgAudio
		})
	},
	methods: {
		_playAudio () {
			this.$refs.clickAudio.currentTime = 0
			this.$refs.clickAudio.play()
		},
		jump (url) {
			const is = url.search('http')
			if (is !== -1) {
				window.location.href = url
			} else {
				this.$router.push(url)
			}
		},
		onresize () {
			this.setSize()
		},
		onPause () {
			this.paused = false
		},
		onPlay () {
			this.paused = true
		},
		playAudio () {
			if (this.bgAudio) {
				if (this.bgAudio.paused) {
					this.bgAudio.play()
				} else {
					this.bgAudio.pause()
				}
			}
		},
		setSize () {
			const { clientHeight, clientWidth } = document.body
			this.clientHeight = clientHeight
			this.clientWidth = clientWidth
			// document.body.style.height = `${clientHeight}px`
			// document.body.style.width = `${clientWidth}px`
			// document.body.style.overflow = 'hidden'
		},
	},
	destroyed () {
		document.body.style.overflow = 'initial'
	},
}
</script>

<style lang="scss" scoped>
	// mobile
	@media (max-width: 960px) {
		.dash-img {
			bottom: 23.33%;
			left: 46px;
		}
		.about-img {
			top: 35.73%;
			right: 96px;
		}
		.info-img {
			bottom: 34.8%;
			right: 53px;
		}
		.swap-img {
			top: 27.2%;
			left: 29px;
		}
		.nft-img {
			right: 81px;
			bottom: 27px;
		}
		.stake-img {
			top: 18px;
			right: 112px;
		}
		.index-img {
			width: 28px;
		}
		.music {
			img {
				width: 20px;
			}
			position: fixed;
			top: 20px;
			left: 20px;
		}
		.index {
			background: url("../../assets/index_m/bg.png") no-repeat center center;
		}
	}
	// pc
	@media (min-width: 960px) {
		.index {
			.dash-img {
				top: 38px;
				right: 116px;
			}
			.about-img {
				bottom: 139px;
				right: 25.41%;
			}
			.info-img {
				top: 219px;
				right: 29.44%;
			}
			.swap-img {
				top: 318px;
				left: 43.81%;
			}
			.nft-img {
				left: 22.29%;
				bottom: 133px;
			}
			.stake-img {
				top: 85px;
				left: 138px;
			}
			.index-img {
				width: 45px;
			}
			.music {
				img {
					width: 32px;
				}
				position: fixed;
				top: 52px;
				left: 52px;
			}
			background: url("../../assets/index_pc/bg.png") no-repeat center center;
		}
	}
	// common
	.index-body {
		.v-dialog {
			border-radius: 0 !important;
		}
	}
	.index-dialog {
		border-radius: 0;
	}
	.index {
		.index-img {
			position: fixed;
			cursor: pointer;
		}
		.music {
			.img_paused {
				animation-play-state: paused !important;
			}
			.img_rotate {
				animation: img_rotate 3s linear infinite;
			}
			cursor: pointer;
		}
		.audio-bg {
			display: none;
		}
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 9999;
		height: 100vh;
		background-size: cover;
	}
	@keyframes img_rotate {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
</style>
